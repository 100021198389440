import React, { useContext, useEffect, useState } from "react"
import SEO from "@components/seo"
import Layout from "@components/layout"
import { SpaceContext } from "@hooks/SpaceContext"
import { Col, Row } from "reactstrap"

const itemsPerPage = 10

const ToursPage = () => {
  const {
    data: { allTours: reservations, user },
  } = useContext(SpaceContext)

  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [pages, setPages] = useState(1)
  const [from, setFrom] = useState(0)
  const [to, setTo] = useState(itemsPerPage - 1)

  useEffect(() => {
    setPages(Math.ceil(reservations.length / itemsPerPage))
    setTotal(reservations.length)
  }, [reservations])

  const inc = () => {
    if (page >= pages) return
    setPage(page + 1)
  }

  const dec = () => {
    if (page <= 1) return
    setPage(page - 1)
  }

  useEffect(() => {
    setFrom((page - 1) * itemsPerPage)
    setTo(itemsPerPage * page > total ? total : itemsPerPage * page)
  }, [page, pages, total])

  return (
    <>
      <SEO title="Tours" />
      <Layout title={"Tours"} progress={!user || !user.id}>
        {reservations.length === 0 ? (
          <p>No tours scheduled yet.</p>
        ) : (
          <div className="table-responsive">
            <table className="table table-striped table-borderless">
              <thead>
                <tr>
                  <th>Name</th>
                  <th className={"text-nowrap"}>Contact Person</th>
                  <th className={"text-nowrap"}>Tour Date</th>
                  <th>Location</th>
                  <th className={"text-nowrap"}>Team size</th>
                  <th className={"text-nowrap"}>Interested in</th>
                </tr>
              </thead>

              <tbody>
                {reservations.slice(from, to).map((tour, idx) => {
                  const {
                    name,
                    space_name,
                    contact_name,
                    date,
                    time,
                    team_size = "N/A",
                    interested_in = "N/A",
                  } = tour
                  return (
                    <tr key={idx}>
                      <td>{name}</td>
                      <td>{contact_name}</td>
                      <td>
                        {date} {time}
                      </td>
                      <td>{space_name}</td>
                      <td>{team_size}</td>
                      <td>{interested_in}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}
        {reservations.length > 0 && (
          <Row className={"mt-4"}>
            <Col sm={6}>
              <span>
                Displaying {from + 1} - {to} of {reservations.length}
              </span>
            </Col>
            <Col sm={6} className={"text-right mt-3 mt-sm-0"}>
              <button
                className={"btn btn-secondary btn-sm ml-2"}
                onClick={dec}
                disabled={page <= 1}
              >
                Prev
              </button>
              <button
                className={"btn btn-secondary btn-sm ml-2"}
                onClick={inc}
                disabled={page >= pages}
              >
                Next
              </button>
            </Col>
          </Row>
        )}
      </Layout>
    </>
  )
}
export default ToursPage
